.ant-steps {
  font-family: $font-family-base;
  line-height: $line-height-base;
}

.ant-steps-item-description {
  color: $gray-light;
  font-family: 'Montserrat', sans-serif;

}

.ant-steps-item-icon {
  border: $step-line-width solid $border-color;
  width: $step-icon-size;
  height: $step-icon-size;
  line-height: $step-icon-size;
  text-align: center;
  border-radius: $step-icon-size;
  font-size: 16px;
  font-family: $font-family-base;

  > .ant-steps-icon {
    top: -1px;
    color: black;
  }
}

.ant-steps-item-wait {

  .ant-steps-item-container{
    .ant-steps-item-content{
      .ant-steps-item-title {
        color: black;
        font-size: .8rem;
        font-family: 'Montserrat', sans-serif;
    }
    }}

  .ant-steps-item-icon {
    border-color: #e4e6e6;
    background-color: #e4e6e6;

   .ant-steps-icon {
    color: black;
    }
  }

  > .ant-steps-item-content {
    > .ant-steps-item-description {
      color: $gray-light;
      font-family: 'Montserrat', sans-serif;

    }

    > .ant-steps-item-title {
      color: $gray-light;
    }
  }
}

.ant-steps-item-process {
      .ant-steps-item-container{
        .ant-steps-item-content{
          .ant-steps-item-title {
            color: black;
            font-size: .8rem;
            font-weight: 800;
            font-family: 'Montserrat', sans-serif;
        }
        }
        .ant-steps-item-icon {
          background: #fbaa23;
          border-color: #fbaa23;
      }
      .ant-steps-item-icon .ant-steps-icon {
        color: #000;
        font-weight: 800;
    }
      }
      .ant-steps-item-icon {
        background-color: #fbaa23;
        border-color: #fbaa23;
        > .ant-steps-icon {
          background-color: #fbaa23;
          border-color: #fbaa23;
          .ant-steps-icon-dot {
            background-color: #fbaa23;
          }
        }
      }

      > .ant-steps-item-content {
        > .ant-steps-item-title {
          color: #fbaa23;

          &:after {
          }
        }

        > .ant-steps-item-tail {
          &:after {
            background-color: #fbaa23;
          }
        }

        > .ant-steps-item-description {
          color: $gray;
          font-family: 'Montserrat', sans-serif;
        }
      }
}

.ant-steps-item-finish {

    .ant-steps-item-container{

      .ant-steps-item-tail::after {
        background-color: #fbaa23;
    }

    .ant-steps-item-content{
      .ant-steps-item-title {
        color: black;
        font-size: .8rem;
        font-family: 'Montserrat', sans-serif;
    }
    }}

      .ant-steps-item-icon {
        border-color: #fbaa23;
        background-color: #fbaa23;
        

        > .ant-steps-icon {
          color: #fff;
          font-weight: 800;

          .ant-steps-icon-dot {
            background: #fbaa23;
          }
        }
      }

      > .ant-steps-item-container {

        .ant-steps-item-content .ant-steps-item-title:after {
          background-color: #fbaa23;
          height: 0.25em;
      }
        > .ant-steps-item-content {
          .ant-steps-item-title {
            color: $gray-dark;

            &:after {
              background-color: #fbaa23;
            }
          }
        }
      }

      > .ant-steps-item-tail {
        &:after {
          background-color: #fbaa23;
        }
      }
}

.ant-steps-item-error {
  .ant-steps-item-icon {
    border-color: $danger;
    background-color: transparent;

    > .ant-steps-icon {
      color: $danger;
    }
  }

  > .ant-steps-item-content {
    > .ant-steps-item-description,
    > .ant-steps-item-title {
      color: $danger;
      font-family: 'Montserrat', sans-serif;
    }
  }
}

.ant-steps-item {
  &.ant-steps-next-error {
    .ant-steps-item-title {
      &:after {
        background: $danger;
      }
    }
  }
}

.ant-steps-item-tail,
.ant-steps-item-title {
  &::after {
    height: 4px;
  }
}

.ant-steps-small {
  .ant-steps-item-icon {
    width: $step-icon-size-sm;
    height: $step-icon-size-sm;
    line-height: $step-icon-size-sm;
    text-align: center;
    border-radius: $step-icon-size-sm;
    font-size: 12px;
  }
}

.ant-steps-vertical {
  > .ant-steps-item {
    > .ant-steps-item-tail {
      &:after {
        width: $step-line-width;
      }
    }
  }
}

.ant-steps-item-custom {
  .ant-steps-item-icon {
    height: auto;
    background: none;
    border: 0;

  }

  &.ant-steps-item-process {
    .ant-steps-item-icon {
      > .ant-steps-icon {

      }
    }
  }
}

.ant-steps-dot {
  .ant-steps-item-tail {
    top: 3px;
  }
}

@import '../node_modules/antd/dist/antd.css';
@import 'assets/scss/global';
@import 'assets/scss/utilities/utilities';

.btn-secondary {
  color: $secondary;
  border-color: $secondary;

  &:hover,
  &:focus {
    background-color: transparent;
    color: lighten($secondary, 5%);
    border-color: lighten($secondary, 5%);
  }

  &:active {
    background-color: transparent;
    color: darken($secondary, 5%);
    border-color: darken($secondary, 5%);
  }

  &.ant-btn-primary {
    background-color: $secondary;
    border-color: $secondary;
    color: $white;

    &:hover,
    &:focus {
      color: $white;
      background-color: lighten($secondary, 5%);
      border-color: lighten($secondary, 5%);
    }

    &:active {
      color: darken($secondary, 5%);
      border-color: darken($secondary, 5%);
    }
  }

  &.ant-btn-link {
    color: $secondary;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $secondary;
    }

    &:hover {
      background-color: rgba($secondary, 0.1);
    }

    &:active {
      background-color: rgba($secondary, 0.2);
    }
  }
}

.ant-badge-status-dot {
  width: 16px; 
  height: 16px;
}

.boxMsg{
  background-color: lemonchiffon; 
  font-size: 1rem;
  border-radius: 5px;
  margin-top: 5px;
}

.boxResSimulation{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.title-danger {
  color: #ff4046;
}

.title-success {
  color: #00664f;
}

.info-success {
  color: #87d068;
  font-size: 1.2rem;
}

.approved-info {
  color: #87d068;
}

.text-modal {
  font-size: 1.2rem;
}

.ant-modal-footer {
  display: flex !important;
  justify-content: space-between !important;
}

.hidden-print {
  display: none !important;
}

.row.modal-btn-add{
  display: none !important;
}

.ant-input[disabled]{
  color: rgba(0, 0, 0, 1);
}

.ant-picker-input > input[disabled]{
  color: rgba(0, 0, 0, 1);
}

.ant-select[disabled]{
  color: rgba(0, 0, 0, 1);
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  color: rgba(0, 0, 0, 1);
}

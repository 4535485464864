.ant-input {
    padding: $input-padding-y $input-padding-x;
    line-height: $input-line-height;
    color: $input-color;
    height: auto;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    background-color: $input-bg;

    &.ant-input:hover {
        border-color: $input-theme-color;
    }

    &:focus,
    .ant-input-focused {
        border-color: $input-theme-color;
        box-shadow: 0 0 0 2px rgba($input-theme-color,.2)
    }

    &.ant-input-disabled {
        &:hover,
        &:focus { 
            border-color: $input-border-color;
        }         
    }

    &~.ant-input-prefix,
    &~.ant-input-suffix {

        &:not(.anticon) {

            .ant-input-search-button {
                height: $select-height;
            }
        }
    }

    &:disabled {
        background-color: $input-bg-disabled;
        cursor: not-allowed;

        &:hover {
            border-color: $input-border-color;
        }  
    }

    @include placeholder {
        color: $input-color-placeholder;
    }
}

.ant-input-prefix {
    margin-right: 0.625rem;
}

.ant-input-sm {
    padding: $input-padding-y-sm $input-padding-x-sm;
    height: auto;

    &~.ant-input-prefix,
    &~.ant-input-suffix {

        &:not(.anticon) {

            .ant-input-search-button {
                height: $select-height-sm;
            }
        }
    }
}    

.ant-input-lg {
    padding: $input-padding-y-lg $input-padding-x-lg;
    height: auto;
    font-size: $input-font-size-lg;

    &~.ant-input-prefix,
    &~.ant-input-suffix {

        &:not(.anticon) {

            .ant-input-search-button {
                height: $select-height-lg;
            }
        }
    }
}   

.ant-input-affix-wrapper {
    padding: $input-padding-y $input-padding-x;
    color: $input-color;
    font-size: $font-size-base;
    line-height: $line-height-base;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: $input-border-radius;
    max-height: none;

    >input{
        &.ant-input {
            padding: 0;
            border: none;
            outline: none;
            border-radius: 0;
        }
    }

    &:hover {
        border-color: $input-theme-color;
    }

    &:focus, 
    &.ant-input-affix-wrapper-focused {
        border-color: $input-theme-color;
        box-shadow: 0 0 0 2px rgba($input-theme-color, 0.2);
    }
}

.ant-input-affix-wrapper-sm {
    padding: $input-padding-y-sm $input-padding-x-sm;
    max-height: none;
}

.ant-input-affix-wrapper-lg {
    padding: $input-padding-y-lg $input-padding-x-lg;
    max-height: none;
    
    .ant-input-prefix,
    .ant-input-suffix {
        font-size: $input-font-size-lg;
    }
}

.ant-input-group-addon {
    border: 1px solid $input-border-color;
    background-color: $gray-lightest;
    color: $body-color;
    font-size: $font-size-base;
    padding: 0 0.625rem;
    border-radius: $border-radius;
}


textarea {
    &.ant-input {
        min-height: $textarea-min-height;
    }
}

.ant-input-affix-wrapper-textarea-with-clear-btn {
    padding: 0;
    border: 0;
}

.ant-input-disabled {
    background-color: #fff !important;
    cursor: not-allowed;

    &:hover {
        border-color: $input-border-color;
    }
} 

.ant-input-affix-wrapper-disabled {
    background-color: $input-bg-disabled;
    color: rgba($input-color, 0.6);
    &:hover {
        border-color: $input-border-color;
    }
} 

.ant-form-item-required {
    &:before {
        color: $input-error-color;
    }
}

.ant-input-search-icon {
    &:before {
        border-left: 1px solid $border-color;
    }
}
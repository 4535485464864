@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

// Start with assigning color names to specific hex values.
$white: #ffffff !default;
$black: #000000 !default;
$red: #de4436 !default;
$orange: #fa8c16 !default;
$gold: #ffc542 !default;
$green: #21B573 !default;
$blue: #045ead !default;
$perolaprimary: #037773 !default;
$perolasecondary: #fbaa23 !default;
$cyan: #04d182 !default;
$purple: #a461d8 !default;
$magenta: #eb2f96 !default;
$volcano: #ff6b72 !default;
$lime: #a0d911 !default;
$geekblue: #17bcff !default;
$bco: #141414 !default;
$bcosecondary: #f08137 !default;
$bcobutton: $perolaprimary !default;
$bcoatcive: $perolaprimary !default;
$ppactive: $perolaprimary !default;
$psactive: $perolasecondary !default;


// Create grayscale
$gray-dark: #2b2b2b !default;
$gray: #455560 !default;
$gray-light: #72849a !default;
$gray-lighter: #ededed !default;
$gray-lightest: #f7f7f8 !default;


// State Colors
$primary: $perolaprimary !default;
$secondary: $perolasecondary !default;
$success: $cyan !default;
$info: $blue !default;
$warning: $gold !default;
$danger: $volcano !default;
$perolap: $perolaprimary !default;
$perolas: $perolasecondary !default;

$state-color-loop: (
  primary $primary,
  secondary $secondary,
  success $success,
  danger $danger,
  info $info,
  warning $warning,
  perolap $perolaprimary,
  perolas $perolasecondary
);

$theme-colors: () !default;
$theme-colors: map-merge(
    (
      "primary": $primary,
      "secondary": $secondary,
      "success": $success,
      "info": $info,
      "warning": $warning,
      "danger": $danger,
      "gray": $gray,
      "gray-light": $gray-light,
      "dark": $gray-dark,
      "perolap": $perolaprimary,
      "perolas": $perolasecondary,
    ),
    $theme-colors
);

// Tones Colors
$tone-magenta: $magenta !default;
$tone-magenta-light: rgba($magenta, 0.05) !default;

$tone-red: $red !default;
$tone-red-light: rgba($red, 0.05) !default;

$tone-volcano: $volcano !default;
$tone-volcano-light: rgba($volcano, 0.05) !default;

$tone-orange: $orange !default;
$tone-orange-light: rgba($orange, 0.1) !default;

$tone-gold: $gold !default;
$tone-gold-light: rgba($gold, 0.1) !default;

$tone-lime: $lime !default;
$tone-lime-light: rgba($lime, 0.1) !default;

$tone-green: $green !default;
$tone-green-light: rgba($green, 0.1) !default;

$tone-cyan: $cyan !default;
$tone-cyan-light: rgba($cyan, 0.1) !default;

$tone-blue: $blue !default;
$tone-blue-light: rgba($blue, 0.1) !default;

$tone-geekblue: $geekblue !default;
$tone-geekblue-light: rgba($geekblue, 0.1) !default;

$tone-purple: $purple !default;
$tone-purple-light: rgba($purple, 0.1) !default;

$tone-loop: (
  "magenta" $tone-magenta $tone-magenta-light,
  "red" $tone-red $tone-red-light,
  "volcano" $tone-volcano $tone-volcano-light,
  "orange" $tone-orange $tone-orange-light,
  "gold" $tone-gold $tone-gold-light,
  "lime" $tone-lime $tone-lime-light,
  "green" $tone-green $tone-green-light,
  "cyan" $tone-cyan $tone-cyan-light,
  "blue" $tone-blue $tone-blue-light,
  "geekblue" $tone-geekblue $tone-geekblue-light,
  "purple" $tone-purple $tone-purple-light
);

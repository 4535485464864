.ant-btn {
  font-size: $font-size-base;
  padding: $btn-padding-y $btn-padding-x;
  line-height: $btn-line-height;
  border-color: $border-color;
  color: $body-color;
  height: $btn-size;
  border-radius: $border-radius;

  &:hover,
  &:focus {
    background-color: $gray-lighter;
    border-color: $gray-lighter;
    color: $body-color;
  }

  &.ant-btn-clicked {
    &:after {
      border-color: $border-color;
    }
  }

  &.disabled,
  &[disabled] {
    color: rgba($body-color, 0.25);
    background-color: $btn-disabled-color;
    border-color: $border-color;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba($body-color, 0.25);
      background-color: $gray-lightest;
      border-color: $border-color;
    }
  }
}

.ant-btn-sm {
  height: $btn-size-sm;
  padding: $btn-padding-y-sm $btn-padding-x-sm;
  font-size: $font-size-sm;
  font-weight: $font-weight-semibold;
  border-radius: $border-radius;
}

.ant-btn-lg {
  height: $btn-size-lg;
  padding: $btn-padding-y-lg $btn-padding-x-lg;
  font-size: $font-size-md;
  border-radius: $border-radius;
}

a {
  &.ant-btn {
    padding-top: $btn-padding-y;
    // line-height: $btn-line-height;

    &.ant-btn-sm {
      padding-top: $btn-padding-y-sm;
    }

    &.ant-btn-lg {
      padding-top: $btn-padding-y-lg;
    }
  }
}

.ant-btn-link {
  color: $bcobutton;
  background-color: transparent;
  border: 0px;

  &:hover,
  &:focus {
    background-color: transparent;
    color: $bcobutton;
  }

  &:hover {
    background-color: rgba($bcobutton, 0.1);
  }

  &:active {
    background-color: rgba($bcobutton, 0.2);
  }
}

.ant-btn-icon-only {
  width: $btn-size;
  height: $btn-size;
  font-size: $font-size-md;
  border-radius: $border-radius;
  padding: 0;

  &.ant-btn-lg {
    width: $btn-size-lg;
    height: $btn-size-lg;
    font-size: $font-size-lg;
    border-radius: $border-radius;
  }

  &.ant-btn-sm {
    width: $btn-size-sm;
    height: $btn-size-sm;
    font-size: $font-size-sm;
    border-radius: 0.3rem;
  }
}

.ant-btn-circle,
.ant-btn-circle-outline {
  padding: 0;
  min-width: $btn-size;
  border-radius: $border-radius-circle;

  &.ant-btn-lg {
    min-width: $btn-size-lg;
    padding: 0;
    border-radius: $border-radius-circle;
  }

  &.ant-btn-sm {
    min-width: $btn-size-sm;
    padding: 0;
    border-radius: $border-radius-circle;
  }
}

.ant-btn-round {
  height: $btn-size;
  border-radius: $btn-size;
  padding: 0px $btn-padding-x;

  &.ant-btn-lg {
    height: $btn-size-lg;
    border-radius: $btn-size-lg;
    border-top-left-radius: $btn-size-lg;
    border-top-right-radius: $btn-size-lg;
    border-bottom-right-radius: $btn-size-lg;
    border-bottom-left-radius: $btn-size-lg;
    padding: 0px $btn-padding-x-lg;
  }

  &.ant-btn-sm {
    height: $btn-size-sm;
    border-radius: $btn-size-sm;
    border-top-left-radius: $btn-size-sm;
    border-top-right-radius: $btn-size-sm;
    border-bottom-right-radius: $btn-size-sm;
    border-bottom-left-radius: $btn-size-sm;
    padding: 0px $btn-padding-x-sm;
  }
}

.ant-btn-round.ant-btn-icon-only {
  width: auto;
}

.ant-btn-background-ghost {
  border-color: $white;
  color: $white;

  &:hover,
  &:focus {
    border-color: $bcobutton;
    color: $bcobutton;
  }

  &.ant-btn-link {
    color: $white;

    &:hover,
    &:focus {
      color: $bcobutton;
    }

    &:active {
      color: darken($bcobutton, 5%);
    }
  }
}

.ant-btn-primary {
  background-color: $bcobutton;
  border-color: $bcobutton;
  color: $white;

  &:hover,
  &:focus {
    color: $white;
    background-color: lighten($bcobutton, 5%);
    border-color: lighten($bcobutton, 5%);
  }

  &:active {
    color: $white;
    background-color: darken($bcobutton, 5%);
    border-color: darken($bcobutton, 5%);
  }

  &.active {
    color: $white;
    background-color: darken($bcobutton, 5%);
    border-color: darken($bcobutton, 5%);

    &:hover,
    &:focus {
      background-color: darken($bcobutton, 5%);
      border-color: darken($bcobutton, 5%);
    }
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    &:focus {
      box-shadow: none;
    }
  }

  &.ant-btn-background-ghost {
    background-color: transparent;
    border-color: $bcobutton;
    color: $bcobutton;

    &:hover,
    &:focus,
    &:active {
      border-color: lighten($bcobutton, 5%);
      color: lighten($bcobutton, 5%);
    }
  }

  &.disabled,
  &[disabled] {
    color: rgba($body-color, 0.25);
    background-color: $gray-lightest;
    border-color: $border-color;

    &.active,
    &:active,
    &:hover,
    &:focus {
      color: rgba($body-color, 0.25);
      background-color: $gray-lightest;
      border-color: $border-color;
    }
  }

  &.ant-btn-clicked {
    &:after {
      border-color: $bcobutton;
    }
  }
}

.ant-btn-dangerous {
  color: $danger;
  border-color: $danger;

  &:hover,
  &:focus {
    background-color: transparent;
    color: lighten($danger, 5%);
    border-color: lighten($danger, 5%);
  }

  &:active {
    background-color: transparent;
    color: darken($danger, 5%);
    border-color: darken($danger, 5%);
  }

  &.ant-btn-primary {
    background-color: $danger;
    border-color: $danger;
    color: $white;

    &:hover,
    &:focus {
      color: $white;
      background-color: lighten($danger, 5%);
      border-color: lighten($danger, 5%);
    }

    &:active {
      color: darken($danger, 5%);
      border-color: darken($danger, 5%);
    }
  }

  &.ant-btn-link {
    color: $danger;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $danger;
    }

    &:hover {
      background-color: rgba($danger, 0.1);
    }

    &:active {
      background-color: rgba($danger, 0.2);
    }
  }
}

.ant-btn-secondary {
  color: $secondary;
  border-color: $secondary;

  &:hover,
  &:focus {
    background-color: transparent;
    color: lighten($secondary, 5%);
    border-color: lighten($secondary, 5%);
  }

  &:active {
    background-color: transparent;
    color: darken($secondary, 5%);
    border-color: darken($secondary, 5%);
  }

  &.ant-btn-primary {
    background-color: $secondary;
    border-color: $secondary;
    color: $white;

    &:hover,
    &:focus {
      color: $white;
      background-color: lighten($secondary, 5%);
      border-color: lighten($secondary, 5%);
    }

    &:active {
      color: darken($secondary, 5%);
      border-color: darken($secondary, 5%);
    }
  }

  &.ant-btn-link {
    color: $secondary;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $secondary;
    }

    &:hover {
      background-color: rgba($secondary, 0.1);
    }

    &:active {
      background-color: rgba($secondary, 0.2);
    }
  }
}

.ant-btn-dashed {
  color: $body-color;
  background-color: transparent;
  border-color: $border-color;
  border-style: dashed;
  border-width: 0.2rem;
}

.ant-btn-group {

  > .ant-btn:first-child {
    &:not(:last-child) {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }

  > .ant-btn:last-child {
    &:not(:first-child) {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  > span {
    &:first-child {
      &:not(:last-child) {
        > .ant-btn {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }
      }
    }
  }

  > span {
    &:last-child {
      &:not(:first-child) {
        > .ant-btn {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }
  }
}

.ant-mentions {
    border: 1px solid $input-border-color;
    line-height: $input-line-height;

    > textarea {
        padding: $input-padding-y $input-padding-x;
    }
}

.ant-mentions-measure {
    padding: $input-padding-y $input-padding-x;
}

.ant-mention-dropdown {
    line-height: $line-height-base;
    color: $dropdown-item-text;
    margin: $dropdown-margin 0 0;
    padding: 0;
    list-style: none;
    max-height: $dropdown-max-height;
    min-width: $dropdown-min-width;
    background-color: $dropdown-bg;
    border-radius: $border-radius;
    z-index: $zindex-dropdown;
}

.ant-mentions-dropdown-menu-item {
    position: relative;
    display: block;
    padding: $dropdown-padding-y $dropdown-padding-x;
    line-height: $line-height-base;
    font-weight: $font-weight-normal;
    color: $dropdown-item-text;
}

.ant-mentions-dropdown-menu-item-active,
.ant-mentions-dropdown-menu-item.focus,
.ant-mentions-dropdown-menu-item:hover {
    background-color: $dropdown-bg-hover;
}

.ant-mention-dropdown,
.ant-menu {
    font-family: $font-family-sans-serif;
}
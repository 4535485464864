.ant-upload-list {
    display: none;
}

.ant-upload-drag {
    min-height: 0;
    min-width: 100%;
    max-width: 100%;
}

.close_btn {
    position: absolute;
    z-index: 2;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
}

.btn_image {
    cursor: pointer;
    width: 136px;
    height: 136px;
    max-width: 136px;
    max-height: 136px;
}

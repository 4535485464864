.ant-statistic {
    color: $gray-dark;
    line-height: $line-height-base;
}

.ant-statistic-title {
    margin-bottom: 5px;
    font-size: $font-size-base;
    color: $text-muted;
}

.ant-statistic-content {
    font-family: $font-family-base;
}
.contract-toolbar {
  padding: 12px;
  background-color: $gray-lightest;
  border: 1px solid $gray-lighter;
  border-radius: 8px 8px 0 0;

  &__item {
    cursor: pointer;
  }

  & svg {
    font-size: 18px;
  }
}